.nav-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 950px) {
  .nav-container {
    background: #fbfaf9;
  }
}

.nav {
  height: 120px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (max-width: 950px) {
  .nav {
    height: auto;
    flex-direction: column;
  }
}

.nav-head {
  align-items: center;
  display: flex;
}

.nav-logo {
  flex: 1;
  padding: 32px 0;
  display: block;
}

@media (max-width: 950px) {
  .nav-logo {
    padding: 20px 0;
  }

  .nav-logo img {
    height: 36px;
  }
}

.nav-burger {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  display: none;
}

@media (max-width: 950px) {
  .nav-burger {
    display: flex;
  }
}

.nav-burger span {
  width: 30px;
  height: 2px;
  box-sizing: border-box;
  background: #616161;
  margin: 4px 0;
  padding: 0;
  display: block;
}

.nav-body {
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (max-width: 950px) {
  .nav-body {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 16px;
  }

  .nav-body.mobile-hidden {
    display: none;
  }
}

.nav-link, .nav-button {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.nav-link {
  padding-right: 32px;
}

.nav-link a {
  color: #616161;
  font-family: Fira Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  position: relative;
}

.nav-link a:hover {
  color: #483400;
  text-underline-offset: 3px;
  -webkit-text-decoration: underline #fbbf24;
  text-decoration: underline #fbbf24;
  text-decoration-thickness: 2px;
}

@media (max-width: 950px) {
  .nav-link {
    padding: 0;
  }

  .nav-link a {
    padding: 8px 0;
  }
}

.nav-button a {
  color: #483400;
  border: 2px solid #fbbf24;
  border-radius: 3px;
  padding: 8px 16px;
  font-family: Fira Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  position: relative;
}

.nav-button a:hover {
  color: #483400;
  background: #fccc50;
  border-color: #fccc50;
}

@media (max-width: 950px) {
  .nav-button {
    text-align: center;
  }

  .nav-button a {
    margin: 16px 0 8px;
  }
}

/*# sourceMappingURL=navigation.241b4ab1.css.map */
